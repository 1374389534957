<template>
    <div class="modal-wrap">
        <h1 class="tit-h1 d-flex">
            {{ param.headerTitle }}
            <div class="ml-auto align-self-center">
                <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
            </div>
        </h1>
        <div class="modal-box">
            <div class="box-ct mt-2">
                <div class="table-form d-flex">
                    <table>
                        <caption class="hide">
                        설문 목록
                        </caption>
                        <colgroup>
                        <col width="7%" />
                        <col width="20%" />
                        <col width="7%" />
                        <col width="20%" />
                        <col width="7%" />
                        <col width="20%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <v-badge dot color="#EF9191">건설사명</v-badge>
                                </th>
                                <td>
                                    <div class="form-inp full">
                                        <v-autocomplete
                                            :items="ctcpTypeItems"
                                            item-text="CD_NM"
                                            item-value="CD"
                                            outlined
                                            @change="changeDetailBussType(S_CTCP_CODE)"
                                            v-model="S_CTCP_CODE"
                                            placeholder="선택하세요."
                                            hide-details
                                        >
                                        </v-autocomplete>
                                    </div>
                                </td>
                                <th scope="row">
                                    <v-badge dot color="#EF9191">사업지명</v-badge>
                                </th>
                                <td>
                                    <div class="form-inp full">
                                        <v-autocomplete
                                            :items="bussTypeItems"
                                            item-text="CD_NM"
                                            item-value="CD"
                                            outlined
                                            v-model="S_BUSS_CODE"
                                            placeholder="선택하세요."
                                            :disabled="bussTypeItems.length == 0 ? true : false"
                                        >
                                        </v-autocomplete>
                                    </div>
                                </td>
                                <th scope="row">
                                    <v-badge dot color="#EF9191">캠페인명&설문명</v-badge>
                                </th>
                                <td>
                                    <div class="form-inp full">
                                        <v-text-field 
                                            class="form-inp full" 
                                            outlined hide-details 
                                            v-model="S_CMP_SURVEY_NAME"
                                            @keyup.enter="fnSearchBtn"
                                        >
                                        </v-text-field>
                                    </div>
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                    <div class="ml-auto">
                        <v-btn 
                            outlined 
                            class="btn-etc2 ml-2"
                            @click="fnSearchBtn">
                        조회
                        </v-btn>
                    </div>
                </div>
            </div>

            <div class="box-ct mt-3">
                <div class="ml-auto align-self-center">
                    <v-btn 
                        outlined small 
                        class="btn-etc" 
                        @click="fnDeleteInfo"
                    >
                        삭제
                    </v-btn>
                </div>
                <div class="mt-2">
                    <v-data-table
                        dense
                        height="450px"
                        :headers="gridDataHeaders"
                        :items="gridDataText"
                        :items-per-page="itemsPerPage"
                        item-key="NUM"
                        :page.sync="page"
                        hide-default-footer
                        class="grid-default"
                        fixed-header
                        id="mainTable"
                        @page-count="pageCount = $event"
                        @click:row="gridRowClick"
                        @dblclick:row="gridRowClick"
                        v-model="selGroupCd"
                        show-select
                        :item-class="clickActive"
                        no-data-text="검색된 결과가 없습니다."
                    >
                        <template v-slot:item.QUTN_NAME={item}>
                            <!-- <p v-html="item.QUTN_NAME"></p> -->
                            {{ decode(item.QUTN_NAME) }}
                        </template>

                    </v-data-table>

                    <div class="grid-paging text-center pt-2">
                        <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                            :total-visible="totalVisible"
                            next-icon="svg-paging-next"
                            prev-icon="svg-paging-prev"
                        ></v-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
    export default {
        name: "MENU_CAP0200", //name은 'MENU_' + 파일명 조합
        components: {
        },
        props: {
            param: {
                type: Object,
            },
            PART_TYPE: {
                type: String,
            },
        },
        data() {
            return {
                modalData: {},
                bussTypeItems: [],
                ctcpTypeItems: [],
                S_CTCP_CODE: '',
                S_BUSS_CODE: '',
                S_CMP_SURVEY_NAME: '',
                BUSS_CODE: '',
                ROW_NUMBER: 0,

                gridDataHeaders: [
                    { text: "No", value: "NUM", align: "center", width: "4%", sortable: true },
                    { text: "캠페인명", value: "CMP_NAME", align: "center", width: "25%", sortable: true },
                    { text: "설문명", value: "QUTN_NAME", align: "center", width: "60%", sortable: true },
                    { text: "사용여부", value: "USE_YN", align: "center", width: "10%", sortable: true },
                ],
                gridDataText: [],
                selGroupCd: [],

                page: 1,
                pageCount: 0,
                itemsPerPage: 15,
                totalVisible: 15,

                GE_USER_ID: '',

                allCodeList: [],
                USER_AUTH_CHK: false,

                alertMsg: {
                    delRowChkMsg: '삭제할 목록을 선택해주세요.',
                    delRowFiChkMsg: '삭제하시겠습니까?\n(선택된 데이터가 완전 삭제됩니다.)',
                    delSuccMsg: '삭제됐습니다.',
                }
            };
        },

        methods: {
            init() {
                if(this.USER_AUTH_CHK) {
                    this.fnSearchBtn(false);
                }
            },

            /**
            * XSS Decode
            */
            decode(strText) {
                //값존재유무 체크
                if (strText === "" || strText == null) {
                    return strText;
                }
                strText = strText.toString();

                //문자열 길이가 4이상일 경우만 처리
                if (strText.length <= 3) {
                    return strText;
                }
                //순서바뀌면 안나옴
                strText = strText.replaceAll("&lt;", "<");
                strText = strText.replaceAll("&gt;", ">");
                strText = strText.replaceAll("&amp;", "&");
                strText = strText.replaceAll("&quot;", '"');
                strText = strText.replaceAll("&apos;", "'");
                strText = strText.replaceAll("<br/>", "\r");
                strText = strText.replaceAll("<br>", "\r");
                strText = strText.replaceAll("<p>", "\n");
                strText = strText.replaceAll("&#91;", "[");
                strText = strText.replaceAll("&#93;", "]");
                strText = strText.replaceAll("&#40;", "(");
                strText = strText.replaceAll("&#41;", ")");

                return strText;
            },

            async fnSearchBtn(progBool) {

                if(!this.USER_AUTH_CHK) {
                    if(this.mixin_isEmpty(this.S_CTCP_CODE)) {
                        this.common_alert('건설사를 선택해주세요.');
                        return;
                    }
                    if(this.mixin_isEmpty(this.S_BUSS_CODE)) {
                        this.common_alert('사업지를 선택해주세요.');
                        return;
                    }
                }

                let bool = true;
                if(!this.mixin_isEmpty(progBool)) {
                    bool = progBool;
                }

                this.gridDataText = [];

                let requestData = {
                    headers: {
                        METHOD: 'list',
                    },
                    sendData: {}
                };

                requestData.headers["URL"] = "api/hlw/campaign/manage/survey/list";

                // sendData 세팅
                let sCtcpCode = this.S_CTCP_CODE == null ? '' : this.S_CTCP_CODE;
                let sBussCode = this.S_BUSS_CODE == null ? '' : this.S_BUSS_CODE;
                let sCmpSurveyName = this.S_CMP_SURVEY_NAME == null ? '' : this.S_CMP_SURVEY_NAME;

                requestData.sendData["USE_YN"] = 'Y';
                requestData.sendData["PART_TYPE"] = this.PART_TYPE;
                requestData.sendData["CTCP_CODE"] = sCtcpCode;
                requestData.sendData["BUSS_CODE"] = sBussCode;
                requestData.sendData["CMP_SURVEY_NAME"] = sCmpSurveyName;

                let responseGridList = await this.common_postCall(requestData, bool);
                let resHeader = responseGridList.HEADER;
                let resData = responseGridList.DATA;

                // 데이터 조회 오류 시 alert
                if (resHeader.ERROR_FLAG) {
                    this.common_alert(this.alertMsg.error, "error");
                    return;
                }

                if (resData.length > 0) {
                    this.totalCount = resData[0].TOT_CNT;
                }
                this.gridDataText = resData;
            },

            fnDeleteInfo() {
                if(this.mixin_isEmpty(this.selGroupCd)) {
                    this.common_alert(this.alertMsg.delRowChkMsg, 'error');
                    return;
                }

                this.common_confirm(this.alertMsg.delRowFiChkMsg, this.fnDeleteRows, null, null, null, 'chk');
            },

            async fnDeleteRows() {
                let dbDelDatas=[]
                for (let index = 0; index < this.selGroupCd.length; index++) {
                    dbDelDatas.push({QUTN_ID:this.selGroupCd[index].QUTN_ID});
                }

                if(dbDelDatas.length>0) {
                    let requestData = {
                        headers: {
                            METHOD: 'delete',
                        },
                        sendData:{}
                    };
            
                    requestData.headers["URL"] = "api/hlw/campaign/manage/qutn/survey/delete";
            
                    requestData.sendData["ROW_DATAS"]=dbDelDatas;
                    requestData.sendData["USER_ID"]=this.USER_ID;
                    requestData.sendData["USE_YN"]='N';
                    
                    let responseData = await this.common_postCall(requestData);
                    let resHeader = responseData.HEADER;
                    let resData = responseData.DATA;
            
                    if (resHeader.ERROR_FLAG) {
                        this.common_alert(this.alertMsg.error, "error");
                        return false;
                    }else{
                        this.selGroupCd=[];
                        this.fnSearchBtn();
                        this.common_alert(this.alertMsg.delSuccMsg);

                        // 설문 상세 초기화
                        this.modalData = {
                            qutnDatas: {}
                        };
                        this.param.data.push(this.modalData);
                        this.$emit('submit');
                    }
                }
            },

            //사업지 코드 가져오기
            async changeDetailBussType(ctcpTypeCd) {
                this.bussTypeItems = [];
                this.S_BUSS_CODE = '';

                if (!this.mixin_isEmpty(ctcpTypeCd)) {
                    if (this.USER_AUTH_CHK) {
                        if(!this.mixin_isEmpty(this.PART_TYPE) && this.PART_TYPE == 'PO') {
                            this.bussTypeItems = await this.mixin_busscode_get(ctcpTypeCd);
                        } else if(!this.mixin_isEmpty(this.PART_TYPE) && this.PART_TYPE == 'MI') {
                            this.bussTypeItems = await this.mixin_mi_busscode_get(ctcpTypeCd);
                        }
                    } else {
                        if(!this.mixin_isEmpty(this.PART_TYPE) && this.PART_TYPE == 'PO') {
                            this.bussTypeItems = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
                        } else if(!this.mixin_isEmpty(this.PART_TYPE) && this.PART_TYPE == 'MI') {
                            this.bussTypeItems = await this.mixin_mi_busscode_get(ctcpTypeCd, this.GE_USER_ID);
                        }
                    }
                }
            },

            async gridRowClick(item, row) {

                if (item != null) {
                    if (row.isSelected == false) {
                        this.activeRow(row, true);
                    } else {
                        this.disableRow(row, false);
                    }
                }

                let requestData = {
                    headers: {
                        METHOD: 'list',
                    },
                    sendData: {}
                };

                requestData.headers["URL"] = "/api/hlw/advanReserv/campaign/manage/survey/item";

                // sendData 세팅
                requestData.sendData["QUTN_ID"] = item.QUTN_ID;

                let responseGridList = await this.common_postCall(requestData);
                let resHeader = responseGridList.HEADER;
                let resData = responseGridList.DATA;

                // 데이터 조회 오류 시 alert
                if (resHeader.ERROR_FLAG) {
                    this.common_alert(this.alertMsg.error, "error");
                    return false;
                }else{
                    let items = resData;

                    let itemChkBool = false;
                    if(!this.mixin_isEmpty(items)) {
                        if(items.length > 0) {
                            let qutnItem = items[0];

                            if(qutnItem.PART_TYPE == 'MI') {
                                if(this.mixin_isEmpty(qutnItem.CTCP_CODE)) {
                                    itemChkBool = true;
                                }
                                if(this.mixin_isEmpty(qutnItem.BUSS_CODE)) {
                                    itemChkBool = true;
                                }
                            }
                        }else{
                            itemChkBool = true;
                        }
                    }

                    if(itemChkBool) {
                        this.common_alert('정상적으로 등록된 설문(입주)가 아닙니다.');
                        this.selGroupCd = [];
                        return;
                    }

                    this.modalData = {
                        qutnDatas: resData
                    };
                    
                    this.param.data.push(this.modalData);
                    this.$emit('submit');
                    this.gridDataText = [];
                }

            },

            activeRow(row, value) {
                this.selectedRow = row;
                row.select(Boolean(value));
                row.isSelected = Boolean(value);
            },

            disableRow(row, value) {
                row.select(Boolean(value));
                row.isSelected = Boolean(value);
            },

            clickActive(item) {
                //클릭 시, 하이라이트
                return item.ROW_NUMBER == this.ROW_NUMBER ? 'active' : '';
            },

        },

        async mounted() {
            let userGroupCd = this.user_role.userGroupCd;
            this.GE_USER_ID = this.user_id;

            let pArr = ['HLW_CTCP_CD'];
            this.allCodeList = await this.mixin_common_code_get_all(pArr);

            if (userGroupCd === "SYSTEMMANAGER") {
                this.USER_AUTH_CHK = true;
                this.ctcpTypeItems = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '');
            } else {
                this.USER_AUTH_CHK = false;
                let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.GE_USER_ID);

                usserAuth.forEach((element, i) => {
                    this.ctcpTypeItems.push({ CD_NM: element.CTCP_NAME, CD: element.CTCP_CODE });
                    this.bussTypeItems.push({ CD_NM: element.BUSS_NAME, CD: element.BUSS_CODE });
                });

                if(!this.mixin_isEmpty(this.ctcpTypeItems)) {
                    this.S_CTCP_CODE = this.ctcpTypeItems[0].CD;
                }
            }

            this.init();
        },

        computed: {
            initHeaders() {
                return {
                };
            },
            paramData() {
                return {
                };
            }
        },
    };
</script>